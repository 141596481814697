import $axios from '@/utils/request'

// 新增代金券
export function insertCouponWhithProduct (data) {
  const url = '/coupon/insertCouponWhithProduct'
  return $axios.fPost(url, data)
}
// 编辑代金券
export function resetCouponProduct (data) {
  const url = '/coupon/resetCouponProduct'
  return $axios.fPost(url, data)
}

// 用户代金券列表
export function getUserCouponList (data) {
  const url = '/coupon/getUserCouponList'
  return $axios.fPost(url, data)
}

// 用户绑定代金券
export function patchBindCouponByUser (data) {
  const url = '/coupon/patchBindCouponByUser'
  return $axios.fPost(url, data)
}

// 获取课程代金券列表
export function getCouponList (data) {
  const url = '/coupon/getCouponList'
  return $axios.fPost(url, data)
}
