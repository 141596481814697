<template>
  <el-row class="table">
    <span class="title">课时抵扣券管理</span>
    <el-row class="row-bg" style="padding-bottom: 10px;">
      <el-col>
        <el-button type="primary" @click="addvoucher">新增</el-button>
      </el-col>
    </el-row>
  <el-table
    style="margin-top: 20px;width: 40%;"
    :data="dataList"
    border
    fit
    highlight-current-row
    class="tb-list"
  >
    <el-table-column label="课时券类型" align="center" prop="couponNum"></el-table-column>
    <el-table-column label="课时数" align="center" prop="amount"></el-table-column>
    <el-table-column label="可使用课程">
      <template  slot-scope="scope">
        <el-row v-for="(item,index) in scope.row.couponProductList" :key="index">
          {{item.productName}}
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template  slot-scope="scope">
        <el-button size="mini" type="primary"  @click="showDetail(scope.row)">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
    <addcoupon :show="dialogShow" :voucheObject = "voucheObject" @updateLayout="getCouponList"  @close="dialogShow=false"></addcoupon>
  </el-row>
</template>

<script>
    import addcoupon from '@/components/system/addcoupon'

    import { getCouponList } from '@/api/system/coupon'
    export default {
        name: "class-voucher",
      data(){
          return{
            dataList:[],
            dialogShow:false,
            voucheObject:{}
          }
      },
      components: {
        addcoupon
      },
      created() {
          this.getCouponList()
      },
      methods: {
        addvoucher() {
          this.voucheObject = undefined
          this.dialogShow = true
        },
        showDetail(row) {
          this.voucheObject = row
          this.dialogShow = true
        },
        async getCouponList (userId) {
          const res = await getCouponList({})
          this.dataList = res.body.list
        },
      }
    }
</script>

<style lang="scss" scoped>
  @import '../../style/table.scss';
</style>

