var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            "label-position": "left",
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课时券类型：", prop: "couponNum" } },
            [
              _c("el-input", {
                staticStyle: { width: "20%" },
                attrs: {
                  controls: false,
                  min: 1,
                  placeholder: "请填写课时券类型",
                },
                model: {
                  value: _vm.formData.couponNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "couponNum", $$v)
                  },
                  expression: "formData.couponNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课时数：", prop: "amount" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "20%" },
                attrs: {
                  controls: false,
                  min: 1,
                  placeholder: "请填写抵扣课时数",
                },
                model: {
                  value: _vm.formData.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "amount", $$v)
                  },
                  expression: "formData.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "已选课程：" } },
            [
              _c(
                "draggable",
                {
                  attrs: { options: { animation: 200 } },
                  on: { update: _vm.datadragEnd },
                  model: {
                    value: _vm.courseList,
                    callback: function ($$v) {
                      _vm.courseList = $$v
                    },
                    expression: "courseList",
                  },
                },
                _vm._l(_vm.courseList, function (tag, index) {
                  return _c(
                    "el-tag",
                    {
                      key: index,
                      staticStyle: { cursor: "pointer" },
                      attrs: { closable: "", type: "info" },
                      on: {
                        close: function ($event) {
                          return _vm.tagDel(index)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(tag.productName))])]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticClass: "tb-list",
          staticStyle: {
            "margin-top": "20px",
            width: "50%",
            "margin-left": "3%",
          },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "收费名称", align: "center", prop: "productName" },
          }),
          _c("el-table-column", {
            attrs: { label: "单位", align: "center", prop: "costUnit" },
          }),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.synchronization()
                },
              },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }