var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("课时抵扣券管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg", staticStyle: { "padding-bottom": "10px" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addvoucher } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { "margin-top": "20px", width: "40%" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "课时券类型", align: "center", prop: "couponNum" },
          }),
          _c("el-table-column", {
            attrs: { label: "课时数", align: "center", prop: "amount" },
          }),
          _c("el-table-column", {
            attrs: { label: "可使用课程" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.couponProductList,
                    function (item, index) {
                      return _c("el-row", { key: index }, [
                        _vm._v(" " + _vm._s(item.productName) + " "),
                      ])
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("addcoupon", {
        attrs: { show: _vm.dialogShow, voucheObject: _vm.voucheObject },
        on: {
          updateLayout: _vm.getCouponList,
          close: function ($event) {
            _vm.dialogShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }