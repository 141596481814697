<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>{{title}}</span>
    </div>
    <el-form  ref="formData" :model="formData" label-position="left" :rules="rules" label-width="120px">
      <el-form-item label="课时券类型：" prop="couponNum">
        <el-input :controls="false"  style="width:20%" v-model="formData.couponNum" :min="1" placeholder="请填写课时券类型"></el-input>
      </el-form-item>
      <el-form-item label="课时数：" prop="amount">
        <el-input-number :controls="false"  style="width:20%" v-model="formData.amount" :min="1" placeholder="请填写抵扣课时数"></el-input-number>
      </el-form-item>
      <el-form-item label="已选课程：">
        <draggable v-model="courseList" @update="datadragEnd" :options="{animation:200}">
          <el-tag
            style="cursor: pointer;"
            v-for="(tag, index) in courseList"
            :key="index"
            closable
            type="info"
            @close="tagDel(index)"
          >
            <span>{{tag.productName}}</span>
          </el-tag>
        </draggable>
      </el-form-item>
    </el-form>
    <el-table
      style="margin-top: 20px;width:50%;margin-left: 3%"
      :data="dataList"
      ref="multipleTable"
      border
      fit
      @selection-change="handleSelectionChange"
      highlight-current-row
      class="tb-list"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="收费名称" align="center" prop="productName"></el-table-column>
      <el-table-column label="单位" align="center" prop="costUnit"></el-table-column>
    </el-table>
    <el-row slot="footer">
      <el-button type="primary" @click="synchronization()" class="dialog-btn">保存</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import draggable from 'vuedraggable'
  import { getProductList } from '@/api/system/course'
  import { insertCouponWhithProduct,resetCouponProduct } from '@/api/system/coupon'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    voucheObject: {
      default: ()=>{},
      type: Object
    }
  },
  // 监控表单变化，清除验证
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  components: { draggable },
  data () {
    return {
      formData: {},
      dataList:[],
      courseList:[],
      title:"新增抵扣券",
      rules: {
        amount:[{ required: true, message: '请填写抵扣课时数量', trigger: 'blur' }],
        couponNum: [{ required: true, message: '请输入代金券编号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.courseList =  val
    },
    tagDel(index) {
      for (let i = 0; i < this.dataList.length; i++) {
        if(this.courseList[index].id === this.dataList[i].id) {
          this.$refs.multipleTable.toggleRowSelection(this.dataList[i],false)
          break;
        }
      }
    },
    async getProductList() {
      const response = await getProductList({
        pageNum: 1,
        pageSize: 100000,
        needCount: true
      })
      this.dataList = response.body.list
      this.$refs.multipleTable.clearSelection()
      if(this.voucheObject !== undefined && this.voucheObject !== null) {
        this.$nextTick(()=> {
          for (let index = 0; index < this.voucheObject.couponProductList.length; index++) {
            for (let i = 0; i < this.dataList.length; i++) {
              if(this.voucheObject.couponProductList[index].productId === this.dataList[i].id) {
                this.$refs.multipleTable.toggleRowSelection(this.dataList[i])
                break;
              }
            }
          }
        })
      }
    },
    async datadragEnd (evt) {
      evt.preventDefault()
    },
    openDialog () {
      this.formData = {
        couponNum:"",
        amount:""
      }
      this.courseList = []
      this.getProductList()

      if(this.voucheObject === undefined || this.voucheObject === null) {
        this.title = "新增抵扣券"
      }else {
        this.title = "编辑抵扣券"
        this.formData.couponNum = this.voucheObject.couponNum
        this.formData.amount = this.voucheObject.amount
      }
    },

    close () { this.$emit('close') },

    async synchronization () {
       this.$refs.formData.validate(valid => {
         if (valid) {
           if(this.courseList.length === 0) {
            window.$msg("请选择可使用的课程",2)
             return false;
           }
           if(this.voucheObject === undefined || this.voucheObject === null) {
             this.insertCouponWhithProduct()
           }else {
             this.resetCouponProduct()
           }
        }
      })
    },
    async resetCouponProduct(row) {
      let couponProduct = []
      for (let i = 0; i < this.courseList.length; i++) {
        let object = new Object()
        object.productId = this.courseList[i].id
        couponProduct.push(object)
      }
      const res = await resetCouponProduct({
        id:this.voucheObject.id,
        couponNum:this.formData.couponNum,
        amount:this.formData.amount,
        couponProductList:couponProduct,
      })

      if(res && res.state === "success") {
        $msg("编辑成功")
        this.close()
        this.$emit("updateLayout")
      }
    },
    async insertCouponWhithProduct() {
      let couponProduct = []
      for (let i = 0; i < this.courseList.length; i++) {
        let object = new Object()
        object.productId = this.courseList[i].id
        couponProduct.push(object)
      }
      const res = await insertCouponWhithProduct({
        couponNum:this.formData.couponNum,
        amount:this.formData.amount,
        couponProductList:couponProduct,
      })

      if(res && res.state === "success") {
        this.close()
        this.$emit("updateLayout")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
@import "../../../style/table.scss";
  /deep/ .el-input-number .el-input__inner {
    text-align: left;
  }
</style>
